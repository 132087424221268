import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import moment from "moment";
import dayjs from "dayjs";
import * as Yup from "yup";
import "./_style.scss";
import AuthorisationDetails from "./AuthorisationDetails";
import Button from "../../components/Button";
import Notice from "../../components/Notice";
import "react-toastify/dist/ReactToastify.css";
import {
  addAuthorisation,
  editAuthorisationCurrentUser,
} from "../../slices/authorisation";

function ApplyForAuthorisationForm(props) {
  const dispatch = useDispatch();
  const {
    handleClose,
    id,
    row,
    authorisationDetails,
    updateMyAuthorisation,
    disabled,
  } = props;
  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });

  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);

  let DATE = moment(row?.date).format("YYYY-MM-DD");
  let StartDATE = moment(DATE + "T" + row?.startTime, "YYYY-MM-DDTHH:mm");
  let EndDATE = moment(DATE + "T" + row?.endTime, "YYYY-MM-DDTHH:mm");

  const initialValues = {
    description: authorisationDetails ? row?.description : "",
    date: authorisationDetails
      ? {
          day: new Date(row?.date).getDate(),
          month: new Date(row?.date).getMonth() + 1,
          year: new Date(row?.date).getFullYear(),
        }
      : null,

    startTime: authorisationDetails ? StartDATE._i : "",
    endTime: authorisationDetails ? EndDATE._i : "",
  };

  const validationSchema = Yup.object({
    date: Yup.object().nullable().required("Authorisation date is Required!"),
    startTime: Yup.string().required("Start Time Authorisation  is Required!"),
    endTime: Yup.string().required("End Time Authorisation  is Required!"),

    description: Yup.string()
      .min(10, "Authorisation Description must be at least 10 characters")
      .required("Authorisation description is required!"),
  });
  const onSubmit = async (values, submitProps) => {
    const Data = {
      description: values.description,
      date: new Date(
        values.date?.month + "/" + values.date?.day + "/" + values.date?.year
      ),
      startTime: dayjs(values.startTime).format("HH:mm"),
      endTime: dayjs(values.endTime).format("HH:mm"),
    };

    updateMyAuthorisation
      ? await dispatch(
          editAuthorisationCurrentUser({
            Data,
            id: row._id,
          })
        ).then((res) => {
          if (res.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);
              setTimeout(() => {
                handleClose(id);
              }, 1500);
            }
          }
        })
      : await dispatch(addAuthorisation(Data)).then((res) => {
          if (res?.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);
              setTimeout(() => {
                handleClose(id);
              }, 1500);
            }
          }
        });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <>
            <Notice
              open={notice.open}
              text={notice.text}
              state={notice.state}
            />

            <Form style={{marginBottom:"1.75rem"}}>
              <AuthorisationDetails
                initialStartTime={StartDATE._i}
                initialEndTime={EndDATE._i}
                formik={formik}
                user={row?.user}
                authorisationDetails={authorisationDetails}
                disabled={disabled ? true : false}
              />
              {!disabled && (
                <div>
                  <Button
                    text={
                      updateMyAuthorisation
                        ? "Edit authorisation"
                        : "Apply for authorisation"
                    }
                    type="submit"
                    className={
                      !formik.isValid || formik.isSubmitting
                        ? " btn-leave-request blue-disabled-button "
                        : "BlueButton btn-leave-request"
                    }
                  />
                </div>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default ApplyForAuthorisationForm;
